import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
// import { ToastContainer, toast } from "react-toastify";
const tableCustomStyles = {
  table: {
    style: {
      borderRadius: "10px", // Simplified radius
      backgroundColor: "#142028",
      height: "100%",
      width: "100%",
      borderCollapse: "separate", // Ensure separate borders
      borderSpacing: "0", // Control spacing between cells
    },
  },
  tableWrapper: {
    style: {
      display: "block", // Ensure table layout remains intact
      backgroundColor: "#142028",
      borderRadius: "10px", // Consistent border radius
      padding: ".8rem",
      height: "79vh",
      overflow: "auto", // Ensure scrollable for overflow
    },
  },
  headRow: {
    style: {
      // borderBottom: "1px solid #56577A !important",
      backgroundColor: "#142028",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#142028",

      fontSize: "1.1rem",
      color: "#A0AEC0",
      fontWeight: "bold",
      padding: "10px 8px", // Added padding for better alignment
      whiteSpace: "nowrap", // Ensure text does not break unexpectedly
      overflow: "hidden", // Prevent content overflow
      textOverflow: "ellipsis", // Handle text overflow with ellipsis,
      borderBottom: "1px solid #5e5f6d", // Add row se
    },
  },
  cells: {
    style: {
      fontSize: "1rem",
      fontWeight: "bold",
      padding: "10px 8px", // Ensure proper spacing
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis", // Handle cell overflow gracefully,
      borderBottom: "1px solid #5e5f6d", // Add row se
    },
  },
  rows: {
    style: {
      backgroundColor: "#142028",
      color: "white",
      borderRadius: "0", // Removed unwanted rounding
      // borderBottom: "1px solid #56577A", // Add row separator
      cursor: "pointer", // Make rows clickable
      transition: "background-color 0.3s", // Smooth hover effect
    },
    highlightOnHoverStyle: {
      backgroundColor: "#1c2d36", // Row hover color
    },
  },
  noData: {
    style: {
      color: "#A0AEC0",
      backgroundColor: "transparent",
      fontSize: "1.2rem", // Adjusted font size
      textAlign: "center",
    },
  },
  pagination: {
    style: {
      color: "#A0AEC0",
      backgroundColor: "#142028",
      borderRadius: "0 0 10px 10px", // Consistent border radius
      fontWeight: "700",
      borderTop: "0px",
    },
    pageButtonsStyle: {
      borderRadius: "50%", // Fully rounded buttons
      height: "40px",
      width: "40px",
      cursor: "pointer",
      transition: "0.3s",
      color: "#A0AEC0 !important",
      fill: "#A0AEC0",
      "&:disabled": {
        cursor: "unset",
        fill: "#8993a1",
      },
      "&:hover": {
        backgroundColor: "#1c2d36", // Hover effect for pagination buttons
      },
      "&:focus": {
        outline: "none",
      },
    },
  },
};

const PortfolioHoldingTokenTablee = () => {
  const { walletProfilerBalance } = useSelector(
    (state) => state.walletProfilerReducer
  );

  const { walletPosition } = useSelector((state) => state.wallet);

  const [data, setData] = useState([]);

  console.log("data from nft holdings", data);

  const location = useLocation();
  const navigate = useNavigate(); // Add useNavigate hook
  const { assetType } = useSelector((state) => state.walletProfilerReducer);

  useEffect(() => {
    if (location.pathname === "/portfolio") {
      setData(walletPosition);
    } else if (location.pathname === "/profiler") {
      setData(walletProfilerBalance);
    }
  }, [location.pathname, walletPosition, walletProfilerBalance]);

  const handleRowClick = (row) => {
    // Check if 'policy' or 'unit' is null, undefined, or an empty string
    if (row?.unit === "" || row?.policy === "") {
      toast.error("Policy or Unit not found !");
      return;
    }

    const link =
      row?.unit || row?.policy
        ? `/charts?token=${
            assetType === "nft" ? row?.name : row?.ticker
          }&unit=${assetType === "nft" ? row?.policy : row?.unit}&pairID=${
            row?.policy
          }&type=${assetType}`
        : "#";

    navigate(link); // Navigate to the constructed link
  };

  const columnsNft = [
    {
      name: "Name",
      width: "200px",
      selector: (row) => (
        <div
          className="flex gap-4 items-center w-full"
          onClick={(e) => {
            e.stopPropagation(); // Stops propagation to prevent overriding row click
            handleRowClick(row); // Trigger the row click
          }}
        >
          <div>
            <img
              src={row?.image}
              alt="img"
              height={30}
              width={30}
              className="w-10 rounded-full"
            />
          </div>
          <span
            className="w-full truncate inline-block whitespace-nowrap"
            title={assetType === "token" ? row?.ticker : row?.name}
          >
            {assetType === "token" ? row?.ticker : row?.name}
          </span>
        </div>
      ),
    },
    {
      name: "24h",
      selector: (row) => (
        <p
          className={`${row?.["24h"] > 0 ? "text-green-500" : "text-red-500"}`}
        >
          {row?.["24h"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "30d",
      selector: (row) => (
        <p
          className={`${row?.["30d"] > 0 ? "text-green-500" : "text-red-500"}`}
        >
          {row?.["30d"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "7d",
      selector: (row) => (
        <p className={`${row?.["7d"] > 0 ? "text-green-500" : "text-red-500"}`}>
          {row?.["7d"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "Floor Price",
      selector: (row) => <p>{row?.floorPrice + " ₳"}</p>,
    },
    {
      name: "NFTs Held",
      selector: (row) => <p>{row?.balance}</p>,
    },
    {
      name: "Value",
      selector: (row) => <p>{row?.adaValue?.toFixed() + " ₳"}</p>,
    },
  ];

  const columnsToken = [
    {
      name: "Name",
      selector: (row) => (
        <div
          className="flex gap-4 items-center w-full"
          onClick={(e) => {
            e.stopPropagation(); // Prevents click propagation
            handleRowClick(row); // Trigger the row click
          }}
        >
          <div className="flex items-center w-[40%]">
            <img
              src={row?.image || "/ada.jpg"}
              alt="img"
              className="rounded-full" // Ensures the image is round
              style={{
                width: "40px",
                height: "40px",
                objectFit: "contain", // Ensures the image fills the circle without distortion
                aspectRatio: "1/1", // Maintains a perfect 1:1 aspect ratio
              }}
            />
          </div>

          <span
            className="w-full truncate inline-block whitespace-nowrap"
            title={assetType === "token" ? row?.ticker : row.name}
          >
            {assetType === "token" ? row?.ticker : row.name}
          </span>
        </div>
      ),
    },
    {
      name: "24h",
      selector: (row) => (
        <p
          className={`${row?.["24h"] > 0 ? "text-green-500" : "text-red-500"}`}
        >
          {row?.["24h"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "30d",
      selector: (row) => (
        <p
          className={`${row?.["30d"] > 0 ? "text-green-500" : "text-red-500"}`}
        >
          {row?.["30d"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "7d",
      selector: (row) => (
        <p className={`${row?.["7d"] > 0 ? "text-green-500" : "text-red-500"}`}>
          {row?.["7d"]?.toFixed(2)}
        </p>
      ),
    },
    {
      name: "Value",
      selector: (row) => <p>{row?.adaValue?.toFixed() + " ₳"}</p>,
    },
  ];

  return (
    <div className="pt-4">
      <DataTable
        columns={assetType === "token" ? columnsToken : columnsNft}
        data={
          assetType === "token"
            ? data?.walletPosition?.positionsFt
            : data?.walletPosition?.positionsNft
        }
        pagination={false}
        customStyles={tableCustomStyles}
        noDataComponent={
          assetType === "token" ? "No tokens found" : "No NFTs found"
        }
        pointerOnHover
        responsive
        fixedHeaderScrollHeight="300px"
        subHeaderWrap
        onRowClicked={handleRowClick} // Row click event
      />
    </div>
  );
};

export default PortfolioHoldingTokenTablee;
