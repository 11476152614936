// walletDisconnect.js
import { useDispatch } from "react-redux";
// import { setIsWalletConnected, walletSignMessage } from "../../../store/slices/wallet";

import { useCardano } from "@cardano-foundation/cardano-connect-with-wallet";
import { setIsWalletConnected, walletSignMessage } from "./slices/wallet";

export const useWalletDisconnect = () => {
  // const { disconnect } = useCardano();
  const dispatch = useDispatch();

  const handleDisconnect = () => {
    console.log("Handling disconnect...");
    localStorage.removeItem("wallet_address");
    localStorage.removeItem("authenticated_user_pro");
    localStorage.removeItem("stake_address");
    localStorage.removeItem("used_address");

    localStorage.setItem(
      "isWalletConnected",
      JSON.stringify({
        walletKey: null,
      })
    );

    window.dispatchEvent(new Event("walletConnectionChanged"));
    dispatch(setIsWalletConnected(false));
    dispatch(walletSignMessage(null));
    // disconnect();

    const event = new CustomEvent("walletDisconnect");
    window.dispatchEvent(event);
  };

  return handleDisconnect;
};
