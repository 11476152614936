import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showwalletconnectmodalmobile: false,
  showwalletconnectmodaldesk: false,
  isDollar: false,
  isWalletConnected: false, // New state for wallet connection status
  hotwalletmenuitems: [
    { id: 0, value: "Total Volume Traded", active: true },
    { id: 1, value: "Total ADA", active: false },
    { id: 2, value: "Token Transactions", active: false },
    { id: 3, value: "NFT Transactions", active: false },
  ],
  walletprofilermenuitems: [
    { id: 0, value: "Overview", active: true },
    { id: 1, value: "Portfolio", active: false },
    { id: 2, value: "Associated Wallets", active: false },
    { id: 3, value: "Trade History", active: false },
  ],
  signedMessage: localStorage.getItem("wallet_sign"),
  walletAppName: localStorage.getItem("walletAppName"),
  walletData: [],
  walletPosition: [],
  walletAddress: "",
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    walletSignMessage(state, action) {
      state.isWalletConnected = action.payload ? true : false;
      state.signedMessage = action.payload;
      console.log(action.payload, "toolkit");
    },
    walletAppName(state, action) {
      state.walletAppName = action.payload;
      console.log(action.payload, "toolkit");
    },
    walletPosition(state, action) {
      state.walletPosition = action.payload;
    },
    showWalletConnectModalMobile(state) {
      state.showwalletconnectmodalmobile = !state.showwalletconnectmodalmobile;
    },
    showWalletConnectModalDesk(state) {
      state.showwalletconnectmodaldesk = !state.showwalletconnectmodaldesk;
    },
    setIsDollar(state) {
      state.isDollar = !state.isDollar;
    },
    sethotwalletmenuitems(state, action) {
      const newhotwalletmenuitems = state.hotwalletmenuitems.map(
        (item, idx) => {
          item.active = action.payload === idx;
          return item;
        }
      );
      state.hotwalletmenuitems = newhotwalletmenuitems;
    },
    setwalletprofilermenuitems(state, action) {
      const newwalletprofilermenuitems = state.walletprofilermenuitems.map(
        (item, idx) => {
          item.active = action.payload === idx;
          return item;
        }
      );
      state.walletprofilermenuitems = newwalletprofilermenuitems;
    },
    setWalletData(state, action) {
      state.walletData = action.payload;
    },
    setWalletPosition(state, action) {
      state.walletPosition = action.payload;
    },
    setIsWalletConnected(state, action) {
      // New reducer for wallet connection status
      state.isWalletConnected = action.payload;
      console.log("Wallet connected status:", action.payload);
    },
    disconnectWallet(state) {
      state.isWalletConnected = false; // Reset connection status on disconnect
      state.walletAddress = "";
      state.signedMessage = null;
      state.walletData = [];
      state.walletPosition = [];
      console.log("Wallet disconnected and state reset");
    },
    _setWalletAddress(state, action) {
      state.walletAddress = action.payload;
    },
  },
});

const { reducer, actions } = walletSlice;

export const {
  showWalletConnectModalMobile,
  showWalletConnectModalDesk,
  setIsDollar,
  sethotwalletmenuitems,
  setwalletprofilermenuitems,
  walletSignMessage,
  setWalletData,
  setWalletPosition,
  walletAppName,
  setIsWalletConnected, // Export the new action
  disconnectWallet,
  _setWalletAddress,
} = actions;

export default reducer;
