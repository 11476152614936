/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { BLOCKFROST_API } from "../baseurl/baseurl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useWalletDisconnect } from "../store/WalletDisconnection";
import {
  _setWalletAddress,
  setIsWalletConnected,
  setWalletPosition,
} from "../store/slices/wallet";
import { toast } from "react-toastify";

const URL = BLOCKFROST_API + "addresses";

/**
 * Custom React hook to interact with Cardano wallet and retrieve stake address.
 *
 * This hook manages the connection state of a Cardano wallet, retrieves the stake address
 * associated with the wallet, and handles any errors that may occur during the process.
 *
 * @returns {Object} An object containing the following properties:
 * - `stackAddr` {string|null}: The stake address retrieved from the Cardano wallet, or `null` if not available.
 * - `isConnected` {boolean}: A boolean indicating whether the wallet is connected.
 * - `error` {string|null}: An error message if something goes wrong, or `null` if no error occurred.
 */

// Hook to check wallet connection status
export function useWalletConnection() {
  const localWalletConnection = JSON.parse(
    localStorage.getItem("isWalletConnected")
  );
  const [isConnected, setConnected] = useState(false);
  const handleDisconnect = useWalletDisconnect();
  const dispatch = useDispatch();

  useEffect(() => {
    if (localWalletConnection?.walletKey) {
      setConnected(true);
      dispatch(setIsWalletConnected(true));
    }
  }, [dispatch, localWalletConnection]);

  //   console.log("is local wallet connect", isConnected);

  const disconnect = () => {
    handleDisconnect();
    setConnected(false);
    dispatch(setWalletPosition(null));
    // toast.success("Wallet disconnected");
  };

  return { isConnected, disconnect };
}
// Try to get cached values from localStorage

// Hook to fetch stake address only when needed
export function useWallet(callApi) {
  const walletAddress = localStorage.getItem("wallet_address");
  const cachedStakeAddress = localStorage.getItem("stake_address");
  const cachedUsedAddress = localStorage.getItem("used_address");

  const [stakeAddress, setStakeAddress] = useState(null);
  const [usedAddress, setUsedAddress] = useState(null);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);

  useEffect(() => {
    setStakeAddress(cachedStakeAddress);
    setUsedAddress(cachedUsedAddress);
  }, [cachedStakeAddress, cachedUsedAddress]);

  useEffect(() => {
    // console.log(
    //   "mainnetFUHqj4mQM7jEVbY1wEYlW0nAJ2t04Sr9",
    //   walletAddress,
    //   wallet?.walletAddress
    // );
    // if (walletAddress || wallet?.walletAddress) {
    const w_addr = walletAddress;
    async function getStackAddres() {
      try {
        const response = await axios.get(`${URL}/${w_addr}`, {
          headers: {
            project_id: "mainnetFUHqj4mQM7jEVbY1wEYlW0nAJ2t04Sr9",
          },
        });

        const data = response.data;

        // console.log("mainnetFUHqj4mQM7jEVbY1wEYlW0nAJ2t04Sr9", data);

        // Update state
        setStakeAddress(data.stake_address);
        setUsedAddress(data.address);

        // Cache values in localStorage
        localStorage.setItem("stake_address", data.stake_address);
        localStorage.setItem("used_address", data.address);
        dispatch(_setWalletAddress(data.address));
      } catch (error) {
        // console.log("mainnetFUHqj4mQM7jEVbY1wEYlW0nAJ2t04Sr9", error);
        const status_code = error?.response.data.status_code;
        // if (status_code === 404) {
        //   toast.error("No stake address found of this wallet");
        // }
        setStakeAddress(null);
        setUsedAddress(null);
        dispatch(setWalletPosition([]));
        localStorage.removeItem("stake_address");
        localStorage.removeItem("used_address");
      }
    }
    getStackAddres();
    // }
  }, [dispatch, walletAddress, wallet?.walletAddress]);

  return { usedAddress, stakeAddress, error };
}
